<template>
  <div class="p-5 font-size-30">
    <div class="font-weight-bold mb-3">Halaman Dilarang</div>
    <div>Anda dilarang oleh admin untuk melihat halaman ini!</div>
    <div>Silahkan hubungi admin anda!<i class="fa fa-frown-o" aria-hidden="true"></i></div>
    <div class="font-weight-bold font-size-70 mb-1">Forbiden</div>
    <a-button class="btn btn-outline-primary width-100" @click="back">Go Back</a-button>
    <a-button class="btn btn-outline-danger ml-2 width-100" @click="logout">Logout</a-button>
  </div>
</template>
<script>
export default {
  name: 'CuiError404',
  methods: {
    logout() {
      this.$store.dispatch('user/LOGOUT')
    },
    back() {
      this.$router.go(-2)
    },
  },
}
</script>
