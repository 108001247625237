<template>
  <div>
    <cui-error-403 />
  </div>
</template>
<script>
import CuiError403 from '@/components/cleanui/system/Errors/403'
export default {
  components: {
    CuiError403,
  },
}
</script>
